import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import  secureLocalStorage  from  "react-secure-storage";
import {
  Row,
  Col,
  Button,
  Form,
  Input,
  Checkbox,
  Space,
  Card,
  Modal,
  notification,
} from "antd";
// import { loadCaptchaEnginge, LoadCanvasTemplate, LoadCanvasTemplateNoReload, validateCaptcha } from 'react-simple-captcha';
import { DoubleRightOutlined } from "@ant-design/icons";
import hostURL from "../../Common/constants";
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import "./style.css";

function LoginPage() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [toShowSpinner, setToShowSpinner] = useState(false);
  const [api, contextHolder] = notification.useNotification();
  const navigate = useNavigate();
  const location = useLocation();

  const openNotificationWithIcon = (type, title, message) => {
    api[type]({
      message: title,
      description: message,
    });
  };

  useEffect(()=>{
    // loadCaptchaEnginge(6); 
  });

  const onFinish = (values) => {
    setToShowSpinner(true);
    
    // let user_captcha = values.myCaptchaVal;
    // if (validateCaptcha(user_captcha)) {
      // loadCaptchaEnginge(6);
    const formData = {
      emailId: values.Email,
      password: values.password,
    };

    fetch(hostURL + "api/users/signIn", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData),
    })
      .then((response) => {
        return new Promise((resolve) =>
          response.json().then((json) => {
            resolve({
              status: response.status,
              ok: response.ok,
              json,
            });
          })
        );
      })
      .then(({ status, json, ok }) => {
        const message = json.message;
        setToShowSpinner(false);
        if (status == 200) {
          openNotificationWithIcon("success", "Success", message);
          // document.cookie=`token=${json.signInToken}`;
          secureLocalStorage.setItem("token", json.signInToken);
          secureLocalStorage.setItem("userRef", json.uid);
          let fromPath = location.state?.from?.pathname;
          if (fromPath) {
            navigate(fromPath);
          } else {
            window.location.assign("/UserProfile");
          }
        } else {
          openNotificationWithIcon("error", "Error", message);
        };
      });

    // }else{
    //   setToShowSpinner(false);
    //   openNotificationWithIcon("error", "Error", 'Invalid Captcha');
    // }
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = async () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <Header />
      {contextHolder}

      <Row>
        <Col span={12} offset={6}>
          <Card title="Login" className="loginForm">
            <Form
              name="loginform"
              colon={false}
              labelCol={{
                span: 8,
              }}
              wrapperCol={{
                span: 16,
              }}
              style={{
                maxWidth: 600,
              }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
              initialValues={{
                isTermsAccepted: true,
              }}
            >
              <Form.Item
                label="Email"
                name="Email"
                rules={[
                  {
                    required: false,
                    type: "email",
                    message: "Please input your Email!",
                  },
                ]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                label="Password"
                name="password"
                rules={[
                  {
                    required: false,
                    message: "Please input your password!",
                  },
                ]}
              >
                <Input.Password />
              </Form.Item>
              <Form.Item
      name="remember"
      valuePropName="checked"
      wrapperCol={{
        offset: 8,
        span: 16,
      }}
    >
      <Checkbox>Remember me</Checkbox>
    </Form.Item>
              
{/* 
              <Form.Item label="&nbsp;" name="myCaptchaVal">
                <div className="myCaptcha">
        <LoadCanvasTemplate  reloadText="Reload" reloadColor="#000"/>
        <Input placeholder="Enter Above Captcha"  name="myCaptchaVal"/>

         </div>
                </Form.Item> */}

              {/* <Form.Item
                name="isTermsAccepted"
                valuePropName="checked"
                wrapperCol={{
                  offset: 8,
                  span: 16,
                }}
                rules={[
                  {
                    validator: (_, value) =>
                      value
                        ? Promise.resolve()
                        : Promise.reject(
                            new Error("Should accept Terms & Conditions")
                          ),
                  },
                ]}
              >
                <Checkbox >
                  I have read the{" "}
                  <button onClick={showModal} className="textBlack readTerms">
                    Terms & Conditions
                    <DoubleRightOutlined />
                  </button>
                </Checkbox>
              </Form.Item> */}
              <Form.Item className="loginButtons">
                <Space>
                  <Button
                    className="login-form-button"
                    htmlType="submit"
                    loading={toShowSpinner}
                  >
                    Submit
                  </Button>

                  <Button
                    className="login-form-button"
                    htmlType="reset"
                    disabled={toShowSpinner}
                  >
                    Reset
                  </Button>
                </Space>
              </Form.Item>
              <a
                href="/UserRegistration"
                className="textBlack registerLink"
                style={{ float: "right" }}
              >
                Register Here
              </a>
              <a
                href="/ForgotPassword"
                className="textBlack registerLink"
                style={{ float: "left" }}
              >
                Forgot Password
              </a>
            </Form>
          </Card>
        </Col>
      </Row>
      <Footer />
      <Modal
        title="Terms And Conditions"
        open={isModalOpen}
        // onOk={handleOk}
        footer={null}
        onCancel={() => handleCancel()}
      >
        <p>Some contents...</p>
        <p>Some contents...</p>
        <p>Some contents...</p>
      </Modal>
    </>
  );
}
export default LoginPage;
