import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import  secureLocalStorage  from  "react-secure-storage";
import {
  Row,
  Col,
  Button,
  Form,
  Input,
  Checkbox,
  Space,
  Card,
  Modal,
  notification,
} from "antd";
// import { loadCaptchaEnginge, LoadCanvasTemplate, LoadCanvasTemplateNoReload, validateCaptcha } from 'react-simple-captcha';
import { v4 as uuid } from 'uuid';
import { DoubleRightOutlined } from "@ant-design/icons";
import hostURL from "../../Common/constants";
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import "./style.css";

function ForgotPassword() {
  const [toShowSpinner, setToShowSpinner] = useState(false);
  const [api, contextHolder] = notification.useNotification();
  const navigate = useNavigate();
  const location = useLocation();

  const openNotificationWithIcon = (type, title, message) => {
    api[type]({
      message: title,
      description: message,
    });
  };

  useEffect(()=>{
    // loadCaptchaEnginge(6); 
  });

  const onFinish = (values) => {
    let verification_id = uuid();
    let flow_url = "https://prod-121.westus.logic.azure.com:443/workflows/d4f0ae477049416f97b8ae69c9236e0a/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=8TuvfEFMPv9WFE5xolRXOhI8S7dyltUUJaQuB8bhZsY";
    setToShowSpinner(true);
    
    const formData = {
      emailId: values.Email,
      password: values.password,
    };
    // Send the post request to Power Automate URL to trigger the email
    let body = {
      "email": values.email,
      "firstname": values.firstname,
      "lastname": values.lastname,
      "newpassword": values.newpassword,
      "url": hostURL + "api/users/verification/" + verification_id
    };
    let request = new XMLHttpRequest();
    request.open("POST", flow_url, true);
    request.setRequestHeader("Content-Type", "application/json");
    request.onreadystatechange = function () {
      if (this.readyState === 4) {
        request.onreadystatechange = null;
        if (this.status === 200) {
          console.log(this.response);
        } else {
          console.log(this.statusText);
        }
      }
    };
    request.send(JSON.stringify(body));

    fetch(hostURL + "api/users/forgotPassword", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData),
    })
      .then((response) => {
        return new Promise((resolve) =>
          response.json().then((json) => {
            resolve({
              status: response.status,
              ok: response.ok,
              json,
            });
          })
        );
      })
      .then(({ status, json, ok }) => {
        const message = json.message;
        setToShowSpinner(false);
        if (status == 200) {
          openNotificationWithIcon("success", "Success", message);
        } else {
          openNotificationWithIcon("error", "Error", message);
        };
      });
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  return (
    <>
      <Header />
      {contextHolder}

      <Row>
        <Col span={12} offset={6}>
          <Card title="Forgot Password" className="loginForm">
            <Form
              name="loginform"
              colon={false}
              labelCol={{
                span: 8,
              }}
              wrapperCol={{
                span: 16,
              }}
              style={{
                maxWidth: 600,
              }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
              initialValues={{
                isTermsAccepted: true,
              }}
            >
              <Form.Item
                label="Email"
                name="Email"
                rules={[
                  {
                    required: true,
                    type: "email",
                    message: "Please input your Registered Email!",
                  },
                ]}
              >
                <Input />
              </Form.Item>
              
              <Form.Item className="loginButtons">
                <Space>
                  <Button
                    className="login-form-button"
                    htmlType="submit"
                    loading={toShowSpinner}
                  >
                    Submit
                  </Button>

                  <Button
                    className="login-form-button"
                    htmlType="reset"
                    disabled={toShowSpinner}
                  >
                    Reset
                  </Button>
                </Space>
              </Form.Item>
              <a
                href="/UserLogin"
                className="textBlack registerLink"
                style={{ float: "right" }}
              >
                Login Here
              </a>
            </Form>
          </Card>
        </Col>
      </Row>
      <Footer />
    </>
  );
}
export default ForgotPassword;
