export const stateFilter = [
  "match",
  ["get", "STATE"],
  ["IA", "IL", "IN", "MO", "MS", "OH"],
  true,
  false,
];

export const stateCode = {
  Illinois: "IL",
  Indiana: "IN",
  Iowa: "IA",
  Mississippi: "MS",
  Missouri: "MO",
  Ohio: "OH"
};

export const safeLabels = {
  IA_ES: "Iowa Early Successional-Neotropical Birds",
  IA_GG: "Iowa Gaining Ground",
  IA_GR: "Iowa Grand River",
  IA_PR: "Iowa Pheasant Recovery",
  IA_QH: "Iowa Quail Habitat",
  IL_PH: "Illinois Pheasant Initiative",
  IL_QI: "Illinois Quail Initiative",
  IN_IB: "Indiana Bat",
  IN_HS: "Indiana Henslows Sparrow",
  IN_NB: "Indiana Northern Bobwhite Quail",
  IN_RP: "Indiana Ring-necked Pheasant",
  MO_QM: "Missouri Bobwhite Quail",
  MO_SE: "Missouri Sand Ecosystem",
  MS_BB: "Mississippi Black Bear",
  MS_BP: "Mississippi Black Belt Prairie",
  MS_BQ: "Mississippi Bobwhite Quail",
  OH_MH: "Ohio Pollinator and Monarch Habitat",
  OH_UH: "Ohio Upland Bird Habitat"
};

export const landCoverTypeLabels = {
  aquaculture: "Aquaculture Ponds",
  cropland: "Cropland",
  pasture: "Pasture",
  wetland: "Wetland",
};

export const priorityLabels = {
  winderosion: "Wind Erosion",
  watererosion: "Water Erosion",
  soilhealth: "Soil Health",
  excesswind: "Excess Wind",
  drought: "Drought",
  watertemperature: "Water Temperature",
  flooding: "Flooding",
  snowdrift: "Snow Drift",
  waterquality: "Water Quality",
  aquatic: "Aquatic",
  biggame: "Big Game",
  grasslandbirds: "Grassland Birds",
  forestbirds: "Forest Birds",
  pollinators: "Pollinators",
  waterfowl: "Waterfowl",
  carbonstorage: "Carbon Storage",
};

export const columns = [
  {
    title: "Soil Type",
    dataIndex: "soil_type",
    key: "soil_type",
    align: "center",
  },
  {
    title: "Rental Rate",
    dataIndex: "rental_rate",
    key: "rental_rate",
    align: "center",
  },
  {
    title: "Acreage",
    dataIndex: "acreage",
    key: "acreage",
    align: "center",
  },
];

export const safePractices = {
  IA_ES: "CP38 - IOWA HABITAT FOR EARLY SUCCESSIONAL & NEOTROPICAL MIGRATORY BIRDS SAFE",
  IA_GG: "CP38 - IOWA GAINING GROUND SAFE",
  IA_GR: "CP38 - IOWA GRAND RIVER GRASSLAND SAFE",
  IA_PR: "CP38 - IOWA PHEASANT RECOVER SAFE",
  IA_QH: "CP38 - IOWA QUAIL HABITAT SAFE",
  IL_PH: "CP38 - Illinois Pheasant Initiative SAFE",
  IL_QI: "CP38 - Illinois Quail Initiative SAFE",
  MO_QM: "CP38 - Missouri Bobwhite Quail and Monarch Butterfly Habitat Restoration SAFE",
  MO_SE: "CP38 - Missouri Sand Ecosystem Restoration SAFE",
  MS_BB: "CP38 - Mississippi Black Bear Habitat Restoration in the Mississippi Alluvial Valley SAFE",
  MS_BP: "CP38 - Mississippi, Alabama, Black Belt Prairie Restoration SAFE",
  MS_BQ: "CP38 - Mississippi Bobwhite Quail Habitat SAFE",
};

export const withinFloodplainPractices = ["CP23"];
export const outsideFloodplainPractices = ["CP23A"];

export const waterBufferPractices = {
  CP21: { min: 20, max: 120 },
  CP22: { min: 35, max: 100 },
  CP29: { min: 20, max: 120 },
  CP30: { min: 20, max: 120 },
  CP37: { min: null, max: null },
  CP39: { min: null, max: null },
  CP41: { min: 30, max: null },
};
